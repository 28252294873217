.checkoutBankTransferLocalpayment {
  color: rgba($color: #000000, $alpha: 0.85);
  margin-right: 50px;
  .checkoutBankTransferLocalpayment__inst {
    background-color: #eefaea;
    padding: 5px 20px;
    font-family: Montserrat-Bold;
    font-size: 14px;
    ul {
      li {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        p {
          margin: 10px 0;
        }
        > * {
          margin-right: 5px !important;
        }
      }
      .checkoutBankTransferLocalpayment__inst_notice {
        display: inline-flex;
        font-family: Montserrat-Medium;
        background-color: #faf4ea;
        color: #844700;
      }
    }

    .checkoutBankTransferLocalpayment__inst__value {
      font-family: Montserrat-Regular;
    }
  }
  .checkoutBankTransferLocalpayment__info {
    .checkoutBankTransferLocalpayment__info__title {
      font-family: Montserrat-Bold;
      font-size: 16px;
    }
    div {
      display: flex;
      border-bottom: 1px solid rgba(145, 145, 147, 0.2);
      p:nth-child(1) {
        font-family: Montserrat-Bold;
        font-size: 14px;
        margin: 10px 0;
        width: 30%;
      }
      p:nth-child(2) {
        font-size: 14px;
        margin: 10px 0;
        width: 70%;
      }
    }
  }
}

@media only screen and (max-width: 800px) {
  .checkoutBankTransferLocalpayment {
    background-color: #ffffff;
    margin-right: 0px;
    .checkoutBankTransferLocalpayment__inst {
      text-align: center;
      .checkoutBankTransferLocalpayment__inst__tile {
        text-align: center;
      }
      ul {
        padding: 0;
        li {
          justify-content: center;
        }
      }
    }
    .checkoutBankTransferLocalpayment__info {
      padding: 0 20px;
      div {
        justify-content: space-between;
        p {
          width: auto !important;
        }
      }
    }
  }
}
