.crediMax {
  .input-field {
    margin: 10px;
    padding: 0;
    border-color: red;
    width: 355px;
  }
  .crediMax__row2 {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  .crediMax__texField {
    margin-bottom: 15px;
  }
}

@media only screen and (max-width: 800px) {
  .crediMax {
    .input-field {
      width: auto;
    }
    .crediMax__row2 {
      //display: block;
      flex-direction: column;
    }
  }
}
