.faq {
  box-sizing: border-box;
  width: 100%;
  max-width: 1000px;
  .faq__title {
    text-align: center;
    font-family: Montserrat-Bold;
    color: #333333;
    font-size: 36px;
    span {
      color: #36ae37;
    }
  }
  .faq__box {
    margin-bottom: 20px;
  }
}

@media only screen and (max-width: 800px) {
  .faq {
    .faq__title {
      font-size: 26px;
    }
  }
}
