.processPage {
  padding-top: 150px;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: Montserrat-Regular;
  font-size: 12px;
  color: #878787;
  text-align: center;
}
