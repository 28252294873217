.shareOnSocialMedia {
  font-family: Montserrat-Regular;
  margin: 10px;
  font-size: 15px;
  .shareOnSocialMedia__container {
    position: relative;

    .shareOnSocialMedia__shareButton {
      display: flex;
      justify-content: center;
      align-items: center;
      font-family: Montserrat-Regular;
      font-size: 15px;
      color: var(--luxpag--color);
      background-color: #ffffff;
      border: 2px solid var(--luxpag--color);
      border-radius: 4px;
      height: 45px;
      min-width: 150px;
      cursor: pointer;
      margin: 5px 0;
    }
    .shareOnSocialMedia__mediaContainer {
      border: solid 1px var(--luxpag--color);
      border-radius: 4px;
      padding: 5px;
      display: none;
      align-items: center;
      color: var(--luxpag--color);
      position: absolute;
      top: 46px;
      right: 30px;
      > img {
        margin: 0 5px;
        cursor: pointer;
      }
    }
  }

  .shareOnSocialMedia__container:hover > .shareOnSocialMedia__mediaContainer {
    display: flex;
  }
}

@media only screen and (max-width: 800px) {
  .shareOnSocialMedia {
    justify-content: center;
  }
}
