.checkout-pse {
  margin-right: 50px;

  .checkout-pse-section {
    background-color: #eefaea;
    padding: 10px;
    margin-bottom: 20px;
    border-radius: 10px;
    .checkout-pse-title {
      margin: 0;
      margin-bottom: 10px;
      font-size: 15px;
      font-family: Montserrat-Bold;
      color: rgba(0, 0, 0, 0.8);
    }
    .checkout-pse-row {
      font-family: Montserrat-Regular;
      padding: 5px 0;
      font-size: 14px;
      display: flex;
      justify-content: space-between;
      color: rgba(0, 0, 0, 0.8);
      span:nth-child(1) {
        font-weight: 500;
      }
      span:nth-child(2) {
        font-weight: 600;
      }
    }
  }
}

@media only screen and (max-width: 800px) {
  .checkout-pse {
    background-color: #ffffff;
    margin-right: 0px;
    .checkout-pse-section {
      .checkout-pse-row {
        flex-direction: column;
      }
    }
  }
}
