.methodMobile {
  display: block;
  box-sizing: border-box;
  background-color: #ffffff;
  border-radius: 12px;
  padding: 0 12px;
  margin-bottom: 10px;
  .methodMobile__methodTitle {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px 0;
    .MuiFormControlLabel-label {
      font-family: DMSans-Bold;
      font-size: 16px;
      color: #000000;
    }
    .methodMobile__methodTitle__arrowLogo {
      display: flex;
      align-items: center;
      width: 66px;
      .methodMobile__methodTitle__logo {
        width: 50px;
        height: 52px;
        object-fit: scale-down;
      }
      .methodMobile__methodTitle__arrow {
        margin-left: 3px;
      }
    }
    .methodMobile__methodTitle__promotion {
      position: absolute;
      top: -6px;
      left: 50%;
      transform: translate(-50%, 0);
    }
  }
  .methodMobile__channelNotice {
    font-family: Montserrat-Medium;
    box-sizing: border-box;
    color: #f1a00b;
    font-size: 14px;
  }
  .methodMobile__filling__title {
    font-family: DMSans-Bold;
    font-size: 15px;
    color: #282626;
    margin: 10px 0;
  }
  .methodMobile__content {
    .methodMobile__channel__content {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-around;
    }
    .methodMobile__channelPromotion__notice {
      font-family: Montserrat-Medium;
      font-size: 13px;
      margin: 10px;
      color: #f1a00b;
      text-align: center;
      a {
        color: #3341b1;
        text-decoration: none;
      }
    }
    .methodMobile__content__filling {
      padding-bottom: 20px;
      .methodMobile__content__filling__content {
        display: flex;
        flex-direction: column;
        background-color: #ffffff;
        padding-top: 10px;
        border-radius: 12px;
        font-family: Montserrat-Regular;
        .homeMobile__filling__content__input {
          margin-bottom: 20px;
        }
      }
      .methodMobile__content___bottom {
        display: flex;
        flex-direction: column;
        align-items: center;
        .methodMobile__content___checkboxContainer {
          display: flex;
          align-items: center;
          margin-top: 10px;
          font-family: Montserrat-Regular;
          .methodMobile__content___privacyText1 {
            margin-left: 6px;
            font-size: 13px;
            color: #6a6f7a;
          }
          .methodMobile__content___privacyText2 {
            font-size: 13px;
            color: #0376ff;
          }
        }
      }
    }
    .methodMobile__content__support {
      display: flex;
      flex-direction: column;
      font-family: Montserrat-Regular;
      color: #6a6f7a;
      font-size: 12px;
      margin-top: 10px;
      .methodMobile__content__support__row {
        display: flex;
        align-items: center;
        img {
          padding: 0 10px;
        }
      }
    }
  }
}
