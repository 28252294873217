.checkoutPayCash {
  margin-right: 50px;
  .checkoutPayCash__mobileCopy {
    display: none;
  }
  .checkoutPayCash__inst {
    background-color: #eefaea;
    padding: 5px 20px;

    .checkoutPayCash__instTitle {
      font-family: Montserrat-Bold;
      font-size: 15px;
      margin: 0.6rem 0;
    }
    .checkoutPayCash__instCont {
      font-family: Montserrat-Regular;
      font-size: 14px;
      .checkoutPayCash__instCont__payCode {
        font-family: Montserrat-Bold;
        color: var(--luxpag--color);
      }
    }
    .checkoutPayCash__link {
      text-decoration: none;
      color: #3a87ef;
    }
  }
  .checkoutPayCash__agents {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    padding: 10px;
    margin-top: 20px;
    img {
      margin: 10px;
    }
  }
  .checkoutPayCash__notice {
    font-family: Montserrat-Medium;
    font-size: 13px;
    margin: 10px;
    color: #f1a00b;
    text-align: center;
    a {
      color: #3341b1;
      text-decoration: none;
    }
  }
}

@media only screen and (max-width: 800px) {
  .checkoutPayCash {
    background-color: #ffffff;
    margin-right: 0px;
    padding-bottom: 50px;
    .checkoutPayCash__mobileCopy {
      display: block;
      padding: 5px 20px;
    }
  }
}
