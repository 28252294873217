.copyButton {
  .copyButton__button {
    font-family: Montserrat-Regular;
    font-size: 15px;
    color: #ffffff;
    background-color: var(--luxpag--color);
    border: none;
    border-radius: 4px;
    height: 36px;
    min-width: 90px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
