.address {
  padding-bottom: 10px;
  .address__row {
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;
    > * {
      width: 49%;
    }
  }
  .address__billing {
    .address__title {
      font-family: Montserrat-Regular;
      font-size: 14px;
      margin-top: 5px;
    }
  }
  .address__delivery {
    padding-top: 5px;
    .address__title {
      font-family: Montserrat-Regular;
      font-size: 14px;
      margin-bottom: 10px;
      margin-top: 5px;
    }
    .address__checkbox {
      display: flex;
      font-family: Montserrat-Regular;
      font-size: 13px;
      padding-bottom: 5px;
    }
  }
}

@media only screen and (max-width: 800px) {
  .address {
    .address__row {
      margin-bottom: 0;
      flex-direction: column;
      > * {
        width: 100%;
      }
    }
  }
}
