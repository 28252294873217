.CheckoutBankCashCommon {
  width: 100%;
  box-sizing: border-box;
  font-family: Montserrat-Medium;
  .logo-active-border {
    border-bottom: 3px solid;
    border-image: linear-gradient(90deg, #4e89db 0.74%, #b5f948 78.79%) 1;
  }
  .CheckoutBankCashCommon__title {
    display: flex;
    flex-wrap: wrap;
    text-align: center;
    margin: 10px;
    border: 1px solid rgba(230, 230, 230, 1);
    > * {
      //width: 33%;
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      justify-content: stretch;
      align-items: stretch;
      margin: 1px;
    }
    .text {
      font-size: 12px;
      margin: 10px 0;
    }
    .value {
      font-size: 15px;
      margin: 10px 0;
    }
    .CheckoutBankCashCommon__titleLeft {
      .reference {
        display: flex;
        justify-content: center;
        align-items: center;
      }
      color: var(--pagsmile--color);
      > *:nth-child(1) {
        height: 50%;
        background-color: #d1ffc3;
      }
      > *:nth-child(2) {
        height: 50%;
        background-color: #eefaea;
      }
    }
    .CheckoutBankCashCommon__titleMiddle {
      color: #000000;
      > *:nth-child(1) {
        background-color: #d4d4d4;
        height: 50%;
      }
      > *:nth-child(2) {
        background-color: #f6f6f6;
        height: 50%;
      }
    }
    .CheckoutBankCashCommon__titleRight {
      background-color: #eefaea;
      color: #1f7e00;
    }
  }
  .CheckoutBankCashCommon__agents {
    .CheckoutBankCashCommon__agentsTitle {
      font-family: Montserrat-Bold;
      font-size: 15px;
      margin: 15px 10px;
    }
    .CheckoutBankCashCommon__agentsContainer {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      border: 1px solid rgba(230, 230, 230, 1);
      margin: 10px;
      border-radius: 5px;
      .CheckoutBankCashCommon__agent {
        margin: 10px;
        padding: 5px;
        border-bottom: 3px solid #ffff;
        .CheckoutBankCashCommon__agentsLogo {
          cursor: pointer;
          filter: saturate(0);
          transition: all 0.25s;
          max-width: 100px;
        }
        .logo-active {
          filter: none;
        }
      }
    }
  }
  .CheckoutBankCashCommon__methods {
    .CheckoutBankCashCommon__methodsTitle {
      font-family: Montserrat-Bold;
      font-size: 15px;
      margin: 15px 10px;
    }
    .CheckoutBankCashCommon__methodList {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      border: 1px solid rgba(230, 230, 230, 1);
      margin: 10px;
      border-radius: 5px;
      .CheckoutBankCashCommon__methodContainer {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        min-width: 100px;
        margin: 10px;
        cursor: pointer;
        p {
          font-size: 13px;
        }
      }
    }
    .CheckoutBankCashCommon__methodIns {
      min-height: 200px;
      font-size: 14px;
      line-height: 1.7em;
      border: 1px solid rgba(230, 230, 230, 1);
      margin: 10px;
      border-radius: 5px;
      ol {
        padding-right: 10px;
        li {
          transition: all 0.5s;
        }
      }
    }
  }
}

@media only screen and (max-width: 800px) {
  .CheckoutBankCashCommon {
    .CheckoutBankCashCommon__methods {
      .CheckoutBankCashCommon__methodIns {
        background-color: #ffff;
      }
      .CheckoutBankCashCommon__methodList {
        background-color: #ffff;
      }
    }
    .CheckoutBankCashCommon__agents .CheckoutBankCashCommon__agentsContainer {
      background-color: #ffff;
    }
  }
}
