.paypalContainer {
  display: flex;
  flex-direction: column;
  .paypalCreditcard--row {
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;
    > * {
      width: 49%;
    }
  }
  // .braintree-hosted-fields-invalid {
  //   border: 1px solid #ed574a;
  // }
}

@media only screen and (max-width: 800px) {
  .paypalContainer {
    .paypalCreditcard--row {
      flex-wrap: wrap;
      margin-bottom: 0px;
      > * {
        width: 100%;
        margin-bottom: 15px;
      }
    }
  }
}
