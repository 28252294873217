.processing {
  display: flex;
  flex-direction: column;
  align-items: center;
  .processing__loader {
    padding: 50px;
    padding-bottom: 20px;
  }
  .processing__notice1 {
    color: #0e2c0e;
    font-family: Montserrat-Regular;
    font-size: 16px;
    margin-top: 20px !important;
  }
  .processing__notice2 {
    font-family: Montserrat-Regular;
    font-size: 12px;
    color: #878787;
    margin: 30px 12px;
    text-align: center;
  }
}
@media only screen and (max-width: 800px) {
  .processing {
    .processing__notice2 {
      margin: 10px 12px;
    }
  }
}
