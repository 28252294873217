.checkoutPaynet {
  margin-right: 50px;
  .checkoutPaynet__title {
    font-family: Montserrat-Bold;
    font-size: 15px;
    color: #0e2c0e;
    .checkoutPaynet__amount {
      color: var(--luxpag--color);
    }
  }
  .checkoutPaynet__instContainer {
    background-color: #eefaea;
    padding: 20px;
    font-size: 15px;
    line-height: 1.5;
    > p {
      margin: 0;
      font-family: Montserrat-Regular;
    }
  }
}

@media only screen and (max-width: 800px) {
  .checkoutPaynet {
    margin-right: 0px;
    background-color: #ffffff;
    padding-bottom: 40px;
    .checkoutPaynet__title {
      padding: 20px;
      padding-bottom: 0;
      text-align: center;
    }

    .checkoutPaynet__instContainer {
      padding: 10px;
      margin-right: 0px;
      font-size: 15px;
      > p {
        font-family: Montserrat-Regular;
        font-size: 13px;
        font-weight: 400;
        text-align: center;
        margin: 10px 0;
      }
    }
  }
}
