.checkoutLottery {
  margin-right: 30px;
  font-family: Montserrat-Regular;
  font-size: 15px;
  .checkoutLottery__title {
    font-family: Montserrat-Bold;
    font-size: 15px;
    color: #0e2c0e;
  }
  .checkoutLottery__instContainer,
  .checkoutLottery__contentContainer {
    background-color: #eefaea;
    padding: 20px;
    p {
      text-align: center;
    }
  }
  .checkoutLottery__instContainer {
    .checkoutLottery__instContainer__click {
      color: #3341b1;
      cursor: pointer;
    }
  }

  .checkoutLottery__contentContainer {
    margin: 20px 0;
    color: #3341b1;
    .checkoutLottery__content {
      .checkoutLottery__contentText {
        font-family: Montserrat-Bold;
        margin-bottom: 0;
      }
      .checkoutLottery__contentValue {
        margin-top: 0;
      }
    }
  }
  .checkoutLottery__videoContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 10000;
    background-color: rgba(188, 189, 189, 0.34);
    .checkoutLottery__iframeContainer {
      position: relative;
      width: 80%;
      height: 70vh;
      .checkoutLottery__iframeContainer__back {
        position: absolute;
        cursor: pointer;
        top: -25px;
        right: -25px;
        padding: 5px;
      }
    }
  }
}

@media only screen and (max-width: 800px) {
  .checkoutLottery {
    margin-right: 0px;
    background-color: #ffffff;
  }
  .checkoutLottery__title {
    padding: 20px;
    padding-bottom: 0;
    text-align: center;
  }
}
