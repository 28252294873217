.notFound {
  display: flex;
  justify-content: space-around;
  align-items: center;
  max-width: 1200px;
  padding: 0 20px;
  margin: auto;

  .notFound__notice {
    font-family: Montserrat-Regular;
    font-size: 15px;
    .notFound__notice__404 {
      font-family: Montserrat-Bold;
    }
    button {
      font-family: Montserrat-Regular;
      color: var(--luxpag--color);
      background-color: #ffffff;
      border: 2px solid var(--luxpag--color);
      border-radius: 4px;
      height: 40px;
      min-width: 105px;
      cursor: pointer;
      margin: 5px 0;
    }
  }
}

@media only screen and (max-width: 800px) {
  .notFound {
    flex-direction: column;
    padding-top: 120px;
    > * {
      width: 100%;
    }
    .notFound__notice {
      text-align: center;
    }
  }
}
