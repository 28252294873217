.CheckoutMonnetBankCash {
  width: 100%;
  box-sizing: border-box;
  font-family: Montserrat-Medium;
  .logo-active-border {
    border-bottom: 3px solid;
    border-image: linear-gradient(90deg, #4e89db 0.74%, #b5f948 78.79%) 1;
  }
  .CheckoutMonnetBankCash__title {
    display: flex;
    flex-wrap: wrap;
    text-align: center;
    margin: 10px;
    border: 1px solid rgba(230, 230, 230, 1);
    > * {
      //width: 33%;
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin: 1px;
    }
    .text {
      font-size: 12px;
      margin: 10px 0;
    }
    .value {
      font-size: 15px;
      margin: 10px 0;
    }
    .CheckoutMonnetBankCash__titleLeft {
      color: #1f7e00;
      > *:nth-child(1) {
        background-color: #d1ffc3;
      }
      > *:nth-child(2) {
        background-color: #eefaea;
      }
    }
    .CheckoutMonnetBankCash__titleMiddle {
      background-color: #f6f6f6;
      color: #000000;
    }
    .CheckoutMonnetBankCash__titleRight {
      background-color: #eefaea;
      color: #1f7e00;
    }
  }
  .CheckoutMonnetBankCash__agents {
    .CheckoutMonnetBankCash__agentsTitle {
      font-family: Montserrat-Bold;
      font-size: 15px;
      margin: 15px 10px;
    }
    .CheckoutMonnetBankCash__agentsContainer {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      border: 1px solid rgba(230, 230, 230, 1);
      margin: 10px;
      border-radius: 5px;
      .CheckoutMonnetBankCash__agentsLogo {
        cursor: pointer;
        filter: saturate(0);
        transition: all 0.25s;
      }
      .logo-active {
        filter: none;
      }
    }
  }
  .CheckoutMonnetBankCash__methods {
    .CheckoutMonnetBankCash__methodsTitle {
      font-family: Montserrat-Bold;
      font-size: 15px;
      margin: 15px 10px;
    }
    .CheckoutMonnetBankCash__methodList {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      border: 1px solid rgba(230, 230, 230, 1);
      margin: 10px;
      border-radius: 5px;
      .CheckoutMonnetBankCash__methodContainer {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        min-width: 100px;
        margin: 10px;
        cursor: pointer;
        p {
          font-size: 13px;
        }
      }
    }
    .CheckoutMonnetBankCash__methodIns {
      min-height: 200px;
      font-size: 13px;
      line-height: 1.7em;
      border: 1px solid rgba(230, 230, 230, 1);
      margin: 10px;
      border-radius: 5px;
      li {
        transition: all 0.5s;
      }
    }
  }
}
