.originalSource {
  > * {
    width: 100%;
  }
  > *:nth-child(2) {
    color: #f1a00b;
  }
}

@media only screen and (max-width: 800px) {
  .originalSource {
    .MuiInputLabel-outlined {
      font-size: 11px !important;
    }
  }
}
