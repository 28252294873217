.checkoutCodi {
  border: 2px solid #f1f4f8;
  height: calc(100% - 4px);
  margin-right: 50px;
  .checkoutCodi__title {
    font-family: Montserrat-Bold;
    font-size: 20px;
    color: #0e2c0e;
    background-color: #eefaea;
    border-bottom: 2px solid #f1f4f8;
    margin: 0;
    padding: 5px 50px;
  }
  .checkoutCodi__content {
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 20px 40px;
    .checkoutCodi__content__qrContent {
      padding: 10px;
      display: flex;
      flex-direction: column;
      align-items: center;
      .checkoutCodi__content__total {
        font-family: Montserrat-Bold;
        text-align: center;
        opacity: 0.65;
        margin-bottom: 5px;
      }
      .checkoutCodi__content__qrContainer {
        position: relative;
        .checkoutCodi__content__qr {
          border: 2px solid #eaeaea;
          padding: 10px;
          border-radius: 8px;
        }
      }
      .checkoutCodi__content__download {
        font-family: Montserrat-Regular;
        font-size: 13px;
        color: #ffffff;
        background-color: var(--pagsmile--color);
        border: none;
        border-radius: 4px;
        margin: 5px 0;
        text-transform: none;
      }
    }
    .checkoutCodi__content__inst {
      .checkoutCodi__content__inst__howToPay {
        p {
          font-family: Montserrat-Regular;
          font-size: 14px;
          color: #7f8fa4;
        }
        .checkoutCodi__content__inst__howToPay__title {
          font-family: Montserrat-Bold;
          font-size: 14px;
          color: #616161;
        }
      }
    }
  }
}

@media only screen and (max-width: 800px) {
  .checkoutCodi {
    background-color: #ffffff;
    margin-right: 0;
    .checkoutCodi__title {
      text-align: center;
      padding: 5px;
    }
    .checkoutCodi__content {
      flex-wrap: wrap-reverse;
      padding: 10px 30px;
      .checkoutCodi__content__inst__howToPay {
        text-align: center;
      }
    }
  }
}
