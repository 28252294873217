.checkoutBaloto {
  margin-right: 50px;
  background-color: #f8f8f8;
  padding-left: 20px;
  .checkoutBaloto__titleContainer {
    padding: 5px;
    .checkoutBaloto__title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-family: Montserrat-Bold;
      img {
        margin: 10px;
      }
    }
    .checkoutBaloto__title__text {
      font-family: Montserrat-Regular;
      font-size: 14px;
    }
  }

  .checkoutBaloto__payInfo {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 20px 0;
    .checkoutBaloto__payInfo__rows {
      width: 50%;
      > * {
        margin: 20px 0;
      }
    }
    .checkoutBaloto__dateContainer {
      width: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      .checkoutBaloto__date {
        display: inline-flex;
        flex-direction: column;
        .checkoutBaloto__date__title {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 180px;
          border-radius: 20px 20px 0px 0px;
          background-color: #ca5341;
          border: solid 1px #bfbfbf;
          border-bottom: solid 4px #a84131;
          p {
            font-family: Montserrat-Regular;
            font-size: 13px;
            color: #ffffff;
          }
        }
        .checkoutBaloto__date__content {
          width: 180px;
          border: solid 1px #bfbfbf;
          border-radius: 0px 0px 20px 20px;
          font-family: Montserrat-Bold;
          background-color: #ffffff;
          p:nth-child(1) {
            font-size: 30px;
            text-align: center;
            margin: 10px 0 0;
          }
          p:nth-child(2) {
            font-size: 15px;
            text-align: center;
            margin: 0 0 10px;
          }
        }
      }
    }
  }
  .checkoutBaloto__bncr {
    font-family: Montserrat-Medium;
    font-size: 13px;
    background-color: #eefaea;
    padding: 10px;
    .checkoutBaloto__bncr__title {
      font-family: Montserrat-Bold;
      font-size: 14px;
      margin: 10px 0;
    }
  }
}

.infoRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  border: solid 1px #bfbfbf;
  border-radius: 30px;
  padding: 6px;
  padding-right: 10px;
  background-color: #ffffff;

  .infoRow__number {
    font-family: Montserrat-Medium;
    font-size: 14px;
    display: inline-flex;
    height: 30px;
    width: 30px;
    justify-content: center;
    align-items: center;
    border: solid 1px #bfbfbf;
    border-radius: 30px;
    margin-right: 10px;
  }
  .infoRow__title {
    font-family: Montserrat-Bold;
    font-size: 14px;
  }
  .infoRow__content {
    display: flex;
    align-items: center;
    flex-grow: 1;
    justify-content: flex-end;
    font-family: Montserrat-Bold;
    font-size: 14px;
    color: var(--pagsmile--color);
    .copyButton {
      .copyButton__button {
        height: auto;
        min-width: auto;
        padding: 5px;
        margin-left: 5px;
        border-radius: 20px;
        font-size: 12px;
      }
    }
  }
}

@media only screen and (max-width: 800px) {
  .checkoutBaloto {
    background-color: #fff;
    margin: 0;
    padding: 10px 10px 20px;
    .checkoutBaloto__titleContainer {
      text-align: center;
      background-color: #eefaea;
      .checkoutBaloto__title {
        flex-direction: column-reverse;
      }
    }
    .checkoutBaloto__payInfo {
      padding: 0;
      .checkoutBaloto__payInfo__rows {
        width: 100%;
      }
      .checkoutBaloto__dateContainer {
        width: 100%;
      }
    }
  }
}
