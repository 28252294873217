.checkoutWallet {
  .checkoutWallet--mobile {
    display: none;
  }
  border: 2px solid #f1f4f8;
  height: calc(100% - 4px);
  margin-right: 50px;
  .checkoutWallet__title {
    font-family: Montserrat-Bold;
    font-size: 20px;
    color: #0e2c0e;
    background-color: #eefaea;
    border-bottom: 2px solid #f1f4f8;
    margin: 0;
    padding: 5px 50px;
  }
  .checkoutWallet__content {
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 40px;
    .checkoutWallet__content__qrcode {
      padding: 30px;
    }
    .checkoutWallet__content__inst {
      .checkoutWallet__content__inst__titleContainer {
        .checkoutWallet__content__inst__title {
          font-family: Montserrat-Bold;
          font-size: 16px;
          color: #0e2c0e;
        }
      }

      .checkoutWallet__content__inst__howToPay {
        p {
          font-family: Montserrat-Regular;
          font-size: 14px;
          color: #7f8fa4;
        }
        .checkoutWallet__content__inst__howToPay__title {
          font-family: Montserrat-Bold;
          font-size: 14px;
          color: #616161;
        }
      }
    }
  }
  .checkoutWallet__buttonContainer {
    display: none;
  }
}

@media only screen and (max-width: 800px) {
  .checkoutWallet {
    .checkoutWallet--mobile {
      display: block;
    }
    .checkoutWallet--pc {
      display: none;
    }
    background-color: #ffffff;
    margin-right: 0;
    .checkoutWallet__title {
      text-align: center;
      padding: 5px;
    }
    .checkoutWallet__content {
      flex-wrap: wrap-reverse;
      padding: 10px 30px;
      .checkoutWallet__content__qrcode {
        display: none;
      }
      .checkoutWallet__content__inst {
        .checkoutWallet__content__inst__titleContainer {
          text-align: center;
        }
      }
    }
    .checkoutWallet__buttonContainer {
      display: flex;
      justify-content: center;
      margin: 10px 0 20px;
      button {
        font-family: Montserrat-Regular;
        font-size: 15px;
        color: var(--luxpag--color);
        background-color: #ffffff;
        border: 2px solid var(--luxpag--color);
        border-radius: 4px;
        height: 45px;
        cursor: pointer;
      }
    }
  }
}
