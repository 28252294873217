.refundConfirmation__ProgressButton {
  position: relative;
  text-align: center;
  button {
    border: 1px solid var(--luxpag--color);
    background-color: var(--luxpag--color);
    text-transform: none;
    font-family: Montserrat-Regular;
    color: #fff;
    min-width: 120px;
    border-radius: 4px;
    height: 50px;
    margin: 10px 0;
    font-size: 18px;
  }
  button:hover {
    background-color: #00775d !important;
  }

  .refundConfirmation__progressButton__loading {
    position: absolute;
    top: calc(50% - 12px);
    left: calc(50% - 10px);
    color: #fff;
  }
}

.refundConfirmation {
  width: 100%;
  max-width: 1200px;
  margin: auto;
  display: flex;
  justify-content: space-between;
  padding: 30px;
  margin-bottom: 50px;
  box-shadow: 0 8px 35px -25px rgba(0, 0, 0, 0.35);
  box-sizing: border-box;
  .refundConfirmationMobile {
    display: none;
  }
  .refundConfirmationDesktop {
    display: block;
  }
  .refundConfirmation__left {
    flex-grow: 1;
  }
  .refundConfirmation__forms {
    width: 100%;
    padding: 20px;
    padding-left: 0;
    .refundConfirmation__forms__title {
      font-family: Montserrat-Bold;
      font-size: 16px;
      color: #0e2c0e;
      text-align: center;
      margin: 20px 0 40px;
    }
    .MuiFormLabel-root {
      font-size: 0.9rem;
    }
    .refundConfirmation--row {
      display: flex;
      justify-content: space-between;
      margin-bottom: 15px;
      > * {
        width: 49% !important;
      }
    }
    .refundConfirmation__forms__bankNumber {
      display: flex;
      justify-content: space-between;

      > *:nth-child(1) {
        width: 60%;
      }
      > *:nth-child(2) {
        padding-top: 8px;
      }
      > *:nth-child(3) {
        width: calc(40% - 8px);
      }
    }
    .refundConfirmation__confirm {
      display: flex;
      justify-content: center;
      margin-top: 30px;
      button {
      }
    }
  }
}

@media only screen and (max-width: 800px) {
  .refundConfirmation {
    display: block;
    padding: 100px 10px 80px;
    background-color: #f1f4f8;
    min-height: 100%;
    box-sizing: border-box;
    margin-bottom: 0;
    box-shadow: none;
    .refundConfirmationDesktop {
      display: none !important;
    }
    .refundConfirmationMobile {
      display: block;
    }
    .refundConfirmation__forms {
      display: block;
      box-sizing: border-box;
      background-color: #fff;
      border-radius: 12px;
      padding: 15px 10px;
      margin-top: 12px;
      .MuiFormHelperText-root {
        font-size: 0.6rem;
      }

      .refundConfirmation--row {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        margin-bottom: 0;
        > * {
          width: 100% !important;
          margin-bottom: 15px;
        }
      }
    }
    .refundConfirmationMobile__payDetails {
      background-color: #fff;
      border-radius: 12px;
      padding: 10px 12px;
      margin-bottom: 20px;
      hr {
        border-width: 0;
        height: 1px;
        background-color: rgba(145, 145, 147, 0.2);
        width: 100%;
      }
      .refundConfirmationMobile__payDetails__container {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;
        .refundConfirmationMobile__payDetails__text {
          font-family: Montserrat-Regular;
          font-size: 15px;
          color: #0c3666;
          margin: 5px 0;
        }
        .refundConfirmationMobile__payDetails__value {
          font-family: Montserrat-Bold;
          font-size: 18px;
          color: #0c3666;
          margin: 5px 0;
        }
        img {
          height: 43px;
          width: 100px;
          object-fit: scale-down;
        }
      }
      .refundConfirmationMobile__payDetails__time {
        margin: 20px 0;
        display: flex;
      }
    }
    .refundConfirmationMobile__footer {
      display: flex;
      flex-direction: column;
      box-sizing: border-box;
      width: 100%;
      padding: 0 20px;
      background-color: #ffffff;
      position: fixed;
      bottom: 0;
      right: 0;
      left: 0;
      box-shadow: 0 0px 4px rgba(0, 0, 0, 0.1);
      z-index: 100;
      .refundConfirmationMobile__footer__button {
      }
    }
  }
}
