.dropZoneCustom {
  border: 1px dashed rgba(0, 0, 0, 0.23);
  border-radius: 4px;
  .dzu-dropzone {
    flex-direction: row;
    overflow: visible;
    border: none;
    min-height: auto;
    .dropZoneCustom__label {
      display: flex;
      align-items: center;
      font-family: Montserrat-Regular;
      color: #0e2c0e;
      font-size: 13px;
      padding: 20px 20px;
      cursor: pointer;
      width: 100%;
      span {
        margin: 0 10px;
        line-height: 1.3;
      }
    }
  }
  .dzu-previewContainer {
    min-height: auto;
    padding: 20px;
    padding-top: 0;
    border-bottom: none;
    span {
      font-family: Montserrat-Regular;
      font-size: 10px;
    }
    .dzu-previewImage {
      max-width: 200px;
    }
  }
}
