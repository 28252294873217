.success {
  display: flex;
  flex-direction: column;
  align-items: center;
  .success__result {
    padding: 30px;
    padding-bottom: 0;
  }
  .success__notice1 {
    color: #0e2c0e;
    font-family: Montserrat-Regular;
    font-size: 16px;
  }
  .success__notice2 {
    font-family: Montserrat-Regular;
    font-size: 12px;
    color: #878787;
    margin: 30px 12px;
    text-align: center;
  }
}

@media only screen and (max-width: 800px) {
  .success {
    .success__notice1 {
      margin: 10px 0;
    }
    .success__notice2 {
      margin: 10px 12px;
    }
  }
}
