.streetAndNumber {
  display: flex;
  justify-content: space-between;
  > :first-child {
    width: 65%;
  }
  > :last-child {
    width: 33%;
  }
}
