.checkoutBoleto {
  margin-right: 30px;
  .checkoutBoleto__header {
    .checkoutBoleto__header__title {
      font-family: Montserrat-Bold;
      font-size: 15px;
      color: #0e2c0e;
    }
    .checkoutBoleto__header__contentContainer {
      background-color: #eefaea;
      padding: 20px;
      .checkoutBoleto__header__content {
        font-family: Montserrat-Regular;
        font-size: 14px;
        margin: 5px 0;
        line-height: 1.5;
      }
    }
  }
}

@media only screen and (max-width: 800px) {
  .checkoutBoleto {
    background-color: #fff;
    margin-right: 0;
    margin-top: 20px;
    .checkoutBoleto__header {
      .checkoutBoleto__header__title {
        margin: 0;
        padding: 10px 0;
        text-align: center;
      }
      .checkoutBoleto__header__contentContainer {
        .checkoutBoleto__header__content {
          text-align: center;
          font-size: 13px;
          line-height: 1.5;
        }
      }
    }
  }
}
