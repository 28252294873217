.ellipsisScroll {
  position: relative;
  overflow-x: auto;
  p {
    overflow-x: auto;
  }
  span {
    position: absolute;
    right: 0;
    top: 6px;
    background-color: #f1f4f8;
  }
}

@media only screen and (max-width: 800px) {
  .ellipsisScroll {
    span {
      background-color: #ffffff;
    }
  }
}
