.warning {
  display: inline-flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 13px;
  font-family: Montserrat-Medium;
  background-color: #faf4ea;
  border-radius: 10px;
  padding: 0 10px;
  color: #844700;
  margin-bottom: 5px;
  img {
    margin: 0 5px;
    color: #844700;
  }
}
