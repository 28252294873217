.checkoutDepositExpress {
  margin-right: 50px;
  .checkoutDepositExpress__payResult {
    .checkoutDepositExpress__inst {
      background-color: #eefaea;
      padding: 20px;
      font-size: 15px;
      > p {
        font-family: Montserrat-Regular;
        margin: 5px 0;
      }
      .checkoutDepositExpress__inst__title {
        font-family: Montserrat-Bold;
        color: #04b88f;
      }
    }
    .checkoutDepositExpress__content {
      .checkoutDepositExpress__content__title {
        font-family: Montserrat-Bold;
        font-size: 15px;
        color: #0e2c0e;
      }
      .checkoutDepositExpress__row {
        display: flex;
        font-family: Montserrat-Regular;
        font-size: 14px;
        border-bottom: solid 1px rgba(145, 145, 147, 0.2);
        > p {
          margin: 10px 0;
        }
        > *:nth-child(1) {
          width: 30%;
          font-family: Montserrat-Bold;
        }
        > *:nth-child(2) {
          width: 70%;
        }
      }
    }
  }
  .checkoutDepositExpress__mobileFooter {
    display: none;
  }
  .checkoutDepositExpress__back {
    display: flex;
    align-items: center;
    cursor: pointer;
    font-family: Montserrat-Bold;
    font-size: 15px;
    color: #0e2c0e;
  }
  .checkoutDepositExpress__upload {
    .checkoutDepositExpress__upload__title {
      font-family: Montserrat-Bold;
      font-size: 14px;
      color: #0e2c0e;
    }
    .checkoutDepositExpress__upload__content {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      > * {
        width: 49%;
        margin: 10px 0;
      }
    }
    .checkoutDepositExpress__upload__dropzone {
      .checkoutDepositExpress__upload__dropzoneError {
        font-family: "Roboto", "Helvetica", "Arial", sans-serif;
        font-size: 12px;
        color: #f44336;
        margin: 4px 14px 0;
      }
    }
  }
  .checkoutDepositExpress--buttonContainer {
    display: flex;
    justify-content: center;
    margin-top: 20px;
    button {
      background-color: var(--luxpag--color);
      border: none;
      border-radius: 4px;
      height: 50px;
      padding: 0 10px;
      color: #ffffff;
      font-family: Montserrat-Regular;
      font-size: 18px;
      cursor: pointer;
      min-width: 200px;
    }
  }
  .checkoutDepositExpress__uploadSuccess {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-family: Montserrat-Regular;
    font-size: 14px;
    color: #0e2c0e;
    padding: 100px;
    text-align: center;
  }
}

@media only screen and (max-width: 800px) {
  .checkoutDepositExpress {
    margin-right: 0px;
    margin-top: 5px;
    background-color: #ffffff;
    .checkoutDepositExpress__payResult {
      .checkoutDepositExpress__inst {
        padding: 10px;
        margin-right: 0px;
        font-size: 15px;
        > p {
          font-family: Montserrat-Regular;
          font-size: 13px;
          font-weight: 400;
          text-align: center;
          margin: 10px 0;
        }
      }
      .checkoutDepositExpress__content {
        padding: 0px 20px;
        .checkoutDepositExpress__content__title {
          text-align: center;
        }
        .checkoutDepositExpress__row {
          justify-content: space-between;
          flex-wrap: wrap;
          > *:nth-child(1) {
            width: auto;
            margin-right: 5px;
          }
          > *:nth-child(2) {
            width: auto;
          }
        }
      }
    }
    .checkoutDepositExpress__back {
      padding: 5px;
    }
    .checkoutDepositExpress__upload {
      padding: 10px;
      .checkoutDepositExpress__upload__title {
        text-align: center;
      }
      .checkoutDepositExpress__upload__content {
        > * {
          width: 100%;
          margin: 10px 0;
        }
      }
    }

    .checkoutDepositExpress__uploadSuccess {
      padding: 50px 20px;
    }
    .checkoutDepositExpress--buttonContainer {
      display: flex;
      flex-direction: column;
      box-sizing: border-box;
      width: 100%;
      padding: 0 20px;
      background-color: #ffffff;
      position: fixed;
      bottom: 0;
      right: 0;
      left: 0;
      box-shadow: 0 0px 4px rgba(0, 0, 0, 0.1);
      z-index: 100;
      button {
        background-color: var(--luxpag--color);
        border: none;
        border-radius: 4px;
        height: 50px;
        margin: 10px 0;
        color: #ffffff;
        font-family: Montserrat-Regular;
        font-size: 18px;
      }
    }
  }
}
